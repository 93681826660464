import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Исправленный импорт
import axios from 'axios';

function AdminPage() {
    const [invitations, setInvitations] = useState([]);
    const [guestName, setGuestName] = useState('');
    const [invitationType, setInvitationType] = useState('personal');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    // Функция для проверки аутентификации и обновления токена при необходимости
    const checkAuth = () => {
        if (!accessToken) {
            navigate('/login');
        } else {
            try {
                // Декодируем токен для проверки срока действия
                const decodedToken = jwtDecode(accessToken);
                const currentTime = Date.now() / 1000; // В секундах

                if (decodedToken.exp < currentTime) {
                    // Токен истек, пытаемся обновить
                    axios
                        .post('https://13-12-24.ru/backend/api/token/refresh/', {
                            refresh: refreshToken,
                        })
                        .then((response) => {
                            localStorage.setItem('access_token', response.data.access);
                            loadInvitations(response.data.access);
                        })
                        .catch(() => {
                            // Обновить токен не удалось, перенаправляем на страницу входа
                            navigate('/login');
                        });
                } else {
                    // Токен действителен
                    loadInvitations(accessToken);
                }
            } catch (error) {
                // Ошибка при декодировании токена
                console.error('Token decoding error:', error);
                navigate('/login');
            }
        }
    };

    // Функция для загрузки приглашений с сервера
    const loadInvitations = (token) => {
        axios
            .get('https://13-12-24.ru/backend/api/invitations/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setInvitations(response.data);
                console.log('Invitations loaded:', response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error loading invitations:', error);
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            });
    };

    useEffect(() => {
        checkAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Функция для выхода из системы
    const handleLogout = () => {
        axios
            .post(
                'https://13-12-24.ru/backend/api/logout/',
                { refresh: refreshToken },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                console.log('Logout successful:', response.data);
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                navigate('/login');
            })
            .catch((error) => {
                console.error('Logout error:', error);
                // Даже если произошла ошибка, очищаем токены и перенаправляем на страницу входа
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                navigate('/login');
            });
    };

    // Функция для создания нового приглашения
    const handleCreateInvitation = (e) => {
        e.preventDefault();

        const newInvitation = {
            guest_name: guestName,
            invitation_type: invitationType,
        };

        axios
            .post('https://13-12-24.ru/backend/api/invitations/', newInvitation, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
            .then((response) => {
                setInvitations([...invitations, response.data]);
                setGuestName('');
                setInvitationType('personal');
            })
            .catch((error) => {
                console.error('Error creating invitation:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            });
    };

    if (loading) return <p>Загрузка...</p>;

    return (
        <div>
            <h1>Панель администратора</h1>
            <button onClick={handleLogout}>Выйти</button>

            <form onSubmit={handleCreateInvitation}>
                <div>
                    <label>ФИО гостя / Фамилия семьи</label>
                    <input
                        type="text"
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Тип приглашения</label>
                    <select
                        value={invitationType}
                        onChange={(e) => setInvitationType(e.target.value)}
                    >
                        <option value="personal">Персональная</option>
                        <option value="family">Семья</option>
                    </select>
                </div>
                <button type="submit">Создать приглашение</button>
            </form>

            <h2>Отправленные приглашения</h2>
            <ul>
                {invitations.map((invitation) => (
                    <li key={invitation.id}>
                        {invitation.guest_name} - {invitation.invitation_type} -{' '}
                        <a
                            href={invitation.unique_link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invitation.unique_link}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default AdminPage;
