// InvitationPage.jsx
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './InvitationPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowUp, faTimes} from '@fortawesome/free-solid-svg-icons';


function InvitationPage() {
    const { uniqueLink } = useParams();
    const [invitationData, setInvitationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [initialIsConfirmed, setInitialIsConfirmed] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [guests, setGuests] = useState([{ first_name: '', last_name: '', is_partner: false, is_child: false }]);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [formError, setFormError] = useState(false);


    // Создаём отдельные рефы для каждой секции
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);

    // Мемоизируем массив рефов секций, чтобы они не менялись на каждом рендере
    const sectionRefs = useMemo(() => [section1Ref, section2Ref, section3Ref, section4Ref], []);

    // Флаг, чтобы прокрутка к первой секции произошла только один раз
    const hasScrolled = useRef(false);

    // Реф для хранения ID таймаута
    const scrollTimeoutRef = useRef(null);

    useEffect(() => {
        const preventDefaultScroll = (e) => {
            if (e.touches && e.touches.length > 1) {
                e.preventDefault();
            }
        };

        window.addEventListener('touchmove', preventDefaultScroll, { passive: false });

        return () => {
            window.removeEventListener('touchmove', preventDefaultScroll);
        };
    }, []);

    useEffect(() => {
        const setViewportHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setViewportHeight();

        window.addEventListener('resize', setViewportHeight);
        return () => window.removeEventListener('resize', setViewportHeight);
    }, []);

    // Отключаем автоматическое восстановление позиции прокрутки браузером
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }, []);

    const touchStartY = useRef(0);
    const touchEndY = useRef(0);

    // Функция для прокрутки к секции
    const scrollToSection = useCallback((index, immediate = false) => {
        if (index < 0 || index >= sectionRefs.length) return;

        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
            scrollTimeoutRef.current = null;
        }

        setIsScrolling(true);

        const section = sectionRefs[index].current;
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

            if (immediate) {
                setCurrentSectionIndex(index);
                setIsScrolling(false);
            } else {
                scrollTimeoutRef.current = setTimeout(() => {
                    setCurrentSectionIndex(index);
                    setIsScrolling(false);
                    scrollTimeoutRef.current = null;
                }, 300);
            }
        } else {
            setIsScrolling(false);
        }
    }, [sectionRefs]);


    useEffect(() => {
        const handleTouchStart = (e) => {
            touchStartY.current = e.touches[0].clientY;
        };

        const handleTouchMove = (e) => {
            touchEndY.current = e.touches[0].clientY;

            const diffY = touchStartY.current - touchEndY.current;
            const form = section4Ref.current.querySelector('form');

            // Если находимся на 4-й секции и пользователь свайпает внутри формы
            if (currentSectionIndex === 3 && form) {
                const canScrollUp = form.scrollTop > 0;
                const canScrollDown = form.scrollTop + form.clientHeight < form.scrollHeight;

                // Разрешаем скролл внутри формы
                if ((diffY > 0 && canScrollDown) || (diffY < 0 && canScrollUp)) {
                    return; // Разрешаем скролл внутри формы, секция не меняется
                }
            }

            // Предотвращаем переключение секций при скролле вверх, если находимся в верхней части страницы
            if (diffY < 0 && window.scrollY === 0) {
                e.preventDefault(); // Останавливаем перезагрузку страницы
            }
        };

        const handleTouchEnd = () => {
            const diffY = touchStartY.current - touchEndY.current;
            const form = section4Ref.current.querySelector('form');

            if (currentSectionIndex === 3 && form) {
                const canScrollUp = form.scrollTop > 0;
                const canScrollDown = form.scrollTop + form.clientHeight < form.scrollHeight;

                // Если форма может скроллиться, не переключаем секцию
                if ((diffY > 0 && canScrollDown) || (diffY < 0 && canScrollUp)) {
                    return;
                }
            }

            // Прокрутка вниз
            if (diffY > 50 && currentSectionIndex < sectionRefs.length - 1 && currentSectionIndex !== 3) {
                scrollToSection(currentSectionIndex + 1);
            }

            // Прокрутка вверх
            if (diffY < -50 && currentSectionIndex > 0 && currentSectionIndex !== 3) {
                scrollToSection(currentSectionIndex - 1);
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove, { passive: false });
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [scrollToSection, currentSectionIndex, sectionRefs.length]);


    // Функция для перехода к секции при клике на навигационную точку
    const navigateToSection = useCallback((index) => {
        if (index < 0 || index >= sectionRefs.length) return;
        scrollToSection(index, true);
    }, [scrollToSection, sectionRefs.length]);

    // Прокрутка к первой секции после загрузки данных
    useEffect(() => {
        if (!loading && sectionRefs[0].current && !hasScrolled.current) {
            const timer = setTimeout(() => {
                console.log('Прокрутка к первой секции');
                scrollToSection(0, true);
                hasScrolled.current = true;
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [loading, sectionRefs, scrollToSection]);

    // Загрузка данных приглашения
    useEffect(() => {
        fetch(`https://13-12-24.ru/backend/api/invitations/?unique_link=https://13-12-24.ru/backend/invite/${uniqueLink}`)
            .then((response) => {
                console.log('Статус ответа:', response.status);
                if (!response.ok) {
                    throw new Error('Приглашение не найдено');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Данные от сервера:', data);
                if (data.length === 0) {
                    setError('Приглашение не найдено');
                } else {
                    const invitation = data[0];
                    setInvitationData(invitation);
                    setInitialIsConfirmed(invitation.is_confirmed);
                    setIsConfirmed(invitation.is_confirmed);
                    setGuests(invitation.guests || []);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Ошибка при загрузке приглашения:', error);
                setError('Приглашение не найдено');
                setLoading(false);
            });
    }, [uniqueLink]);

    // Обработчик прокрутки
    useEffect(() => {
        const handleWheel = (e) => {
            if (isScrolling) return;

            if (e.deltaY > 0) {
                // Прокрутка вниз
                if (currentSectionIndex < sectionRefs.length - 1) {
                    e.preventDefault();
                    scrollToSection(currentSectionIndex + 1);
                }
            } else if (e.deltaY < 0) {
                // Прокрутка вверх
                if (currentSectionIndex > 0) {
                    e.preventDefault();
                    scrollToSection(currentSectionIndex - 1);
                }
            }
        };

        const handleKeyDown = (e) => {
            if (isScrolling) return;

            if (e.key === 'ArrowDown') {
                if (currentSectionIndex < sectionRefs.length - 1) {
                    e.preventDefault();
                    scrollToSection(currentSectionIndex + 1);
                }
            } else if (e.key === 'ArrowUp') {
                if (currentSectionIndex > 0) {
                    e.preventDefault();
                    scrollToSection(currentSectionIndex - 1);
                }
            }
        };

        window.addEventListener('wheel', handleWheel, { passive: false });
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentSectionIndex, isScrolling, scrollToSection, sectionRefs.length]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isConfirmed) {
            setFormError(true);
            return;
        }

        setFormError(false);

        const rsvpData = {
            is_confirmed: isConfirmed,
            guests: guests.filter((guest) => guest.first_name && guest.last_name),
        };

        fetch(`https://13-12-24.ru/backend/api/invitations/${invitationData.id}/rsvp/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rsvpData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('RSVP отправлено:', data);
                setIsEditing(false);
                setInitialIsConfirmed(isConfirmed);

                alert('Форма успешно отправлена!');
            })
            .catch((error) => console.error('Ошибка:', error));
    };

    const handleAddGuest = () => {
        // Проверяем, если приглашение не персональное, то разрешаем добавление гостей
        if (invitationData?.invitation_type !== 'personal') {
            setGuests([...guests, { first_name: '', last_name: '', is_partner: false, is_child: false }]);
        }
    };

    const handleGuestChange = (index, key, value) => {
        const updatedGuests = [...guests];

        if (key === 'is_partner') {
            // Если выбирается "Партнер", то "Ребенок" должен быть сброшен
            updatedGuests[index].is_partner = value;
            if (value) {
                updatedGuests[index].is_child = false;
            }
        } else if (key === 'is_child') {
            // Если выбирается "Ребенок", то "Партнер" должен быть сброшен
            updatedGuests[index].is_child = value;
            if (value) {
                updatedGuests[index].is_partner = false;
            }
        } else {
            updatedGuests[index][key] = value;
        }

        setGuests(updatedGuests);
    };

    const handleRemoveGuest = (index) => {
        setGuests((prevGuests) => prevGuests.filter((_, i) => i !== index));
    };

    if (loading) return <p>Загрузка...</p>;
    if (error) return <p>{error}</p>;

    const isPersonalInvitation = invitationData?.invitation_type === 'personal';

    console.log('isPersonalInvitation:', isPersonalInvitation);

    const sections = [
        (
            <div className="section section1" key="section1" ref={section1Ref}>
                {/* Блок 1: Приглашение */}
                <div className="section-content">
                    <h1 className="title">{invitationData.guest_name}</h1>
                    <p className="first-p">МЫ РАДЫ СООБЩИТЬ, ЧТО</p>
                    <img src="https://13-12-24.ru/media/date.png" alt="date"/>
                    <p className="second-p">
                        СОСТОИТСЯ ТОРЖЕСТВО, ПОСВЯЩЕННОЕ НАШЕМУ БРАКОСОЧЕТАНИЮ
                    </p>
                    <p className="second-p">
                        МЫ ПРИГЛАШАЕМ ВАС РАЗДЕЛИТЬ ЭТОТ ДЕНЬ С НАМИ.
                    </p>
                    <p className="ps">
                        Степан & Яна
                    </p>
                </div>
            </div>
        ),
        (
            <div className="section section2" key="section2" ref={section2Ref}>
                {/* Блок 2: Тайминг */}
                <div className="section-content">
                <h2>РАСПИСАНИЕ МЕРОПРИЯТИЯ</h2>
                    <hr></hr>
                    <ul>
                        <li>14:45 - СБОР ГОСТЕЙ У ЗАГСА</li>
                        <li>15:00 - ЦЕРЕМОНИЯ БРАКОСОЧЕТАНИЯ</li>
                        <li>16:00 - БАНКЕТ В КАФЕ «ВАРВАРА»</li>
                    </ul>
                </div>
            </div>
        ),
        (
            <div className="section section3" key="section3" ref={section3Ref}>
                {/* Блок 3: Дресс-код */}
                <div className="section-content">
                    <h2>ДРЕСС-КОД</h2>
                    <p>
                        МЫ БУДЕМ ПРИЗНАТЕЛЬНЫ, ЕСЛИ ВЫ ПОДДЕРЖИТЕ ТЕМАТИКУ БАНКЕТА — КАЗИНО И ЧИКАГО 30-Х ГОДОВ.
                    </p>
                    <p>
                        МЫ БУДЕМ ПРИЗНАТЕЛЬНЫ ВДВОЙНЕ, ЕСЛИ ВЫ ВОЗДЕРЖИТЕСЬ ОТ НАРЯДОВ КРАСНОГО ЦВЕТА.
                    </p>
                    <img className="fishka" src="https://13-12-24.ru/media/fishka.png" alt="fishka"/>
                    <img className="first-fishka-blue" src="https://13-12-24.ru/media/fishkablue.png" alt="fishka"/>
                    <img className="second-fishka-blue" src="https://13-12-24.ru/media/fishkablue.png" alt="fishka"/>
                    <img className="bones6" src="https://13-12-24.ru/media/bones6.png" alt="bones6"/>
                    <img className="bones4" src="https://13-12-24.ru/media/bones4.png" alt="bones4"/>
                    <img className="bones3" src="https://13-12-24.ru/media/bones3.png" alt="bones3"/>

                </div>
            </div>
        ),
        (
            <div className="section section4" key="section4" ref={section4Ref}>
                {/* Блок 4: Подтверждение присутствия */}
                <div className="section-content">
                    {currentSectionIndex === 3 && (
                        <button
                            className="up-arrow-button"
                            onClick={() => scrollToSection(currentSectionIndex - 1)}
                            aria-label="Вернуться к предыдущей секции"
                        >
                            <FontAwesomeIcon icon={faArrowUp} />
                        </button>
                    )}
                    {initialIsConfirmed && !isEditing ? (
                        <div>
                            <h2>УЧАСТИЕ ПОДТВЕРЖДЕНО</h2>
                            {guests.length > 0 && (
                                <>
                                    <h3>С ВАМИ ПРИДУТ:</h3>
                                    <ul>
                                        {guests.map((guest, index) => (
                                            <li key={index}>
                                                {guest.first_name} {guest.last_name}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <button className="standart-button" onClick={() => setIsEditing(true)}>Редактировать</button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <h2>ПОДТВЕРДИТЕ СВОЁ ПРИСУТСТВИЕ</h2>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isConfirmed}

                                        onChange={() => setIsConfirmed(!isConfirmed)}
                                    />
                                    Я буду присутствовать
                                </label>
                                {formError && <p style={{ color: 'red' }}>Подтвердите своё участие</p>}
                            </div>

                            {!isPersonalInvitation && (
                                <>
                                    <h4>Со мной будут (Партнер / Дети)</h4>
                                    {guests.map((guest, index) => (
                                        <div key={index} className="guest-inputs">
                                            <div className="first">
                                                <input
                                                    type="text"
                                                    placeholder="Имя"
                                                    value={guest.first_name}
                                                    onChange={(e) => handleGuestChange(index, 'first_name', e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Фамилия"
                                                    value={guest.last_name}
                                                    onChange={(e) => handleGuestChange(index, 'last_name', e.target.value)}
                                                />
                                            </div>
                                            <div className="second">
                                                <label>
                                                    Партнер
                                                    <input
                                                        type="checkbox"
                                                        checked={guest.is_partner}
                                                        onChange={(e) => handleGuestChange(index, 'is_partner', e.target.checked)}
                                                    />
                                                </label>
                                                <label>
                                                    Ребёнок
                                                    <input
                                                        type="checkbox"
                                                        checked={guest.is_child}
                                                        onChange={(e) => handleGuestChange(index, 'is_child', e.target.checked)}
                                                    />
                                                </label>
                                                <button
                                                    className="remove-guest-btn"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRemoveGuest(index);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}

                            <div className="third">
                                {!isPersonalInvitation && (
                                    <button className="standart-button" type="button" onClick={handleAddGuest}>
                                        Добавить
                                    </button>
                                )}
                                <button className="standart-button" type="submit">Отправить</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        ),
    ];

    return (
        <div className="invitation-page">
            {sections}
            <div className="navigation-dots">
                {sections.map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${currentSectionIndex === index ? 'active' : ''}`}
                        onClick={() => navigateToSection(index)}
                        role="button"
                        tabIndex={0}
                        aria-label={`Перейти к секции ${index + 1}`}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                navigateToSection(index);
                            }
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default InvitationPage;
